<template>
    <v-container class="fill-height">
        <v-row class="text-center" align="center">
            <v-col cols="12">
                <h2>Installez l'extension Drivewin</h2>
            </v-col>
            <v-col>
                <p>Installez dès maintenant l'extension DriveWin pour participer à nos études quantitatives et nous aider à transformer le eRetail via la data - Avec le lien vers le Chrome Store</p>
                <v-btn depressed color="primary" @click="installExt" x-large class="mt-8">
                    <v-icon large left class="mr-6">mdi-google-chrome</v-icon>Ajouter l'extension à Chrome
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'OnboardingForm',

    data: () => ({}),
    methods: {
        async installExt() {
            let win = window.open("https://chrome.google.com/webstore/detail/mhdclogdlkffiklbgoaifkocdiohnfdl", '_blank');
            win.focus();
        }
    }
}
</script>